import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useRosterGroupList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refTaskBlockComponentTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'task',
      sortable: true,
    },
    {
      key: 'fields.length',
      label: 'fields',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalTaskBlocks = ref(0)
  const searchQuery = ref(route.query.search || '')
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(route.query.sortBy ?? 'name')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const toDeletedID = ref(null)
  let initialLoad = true
  const currentDataLength = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refTaskBlockComponentTable.value ? refTaskBlockComponentTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTaskBlocks.value,
    }
  })

  const refetchData = () => {
    refTaskBlockComponentTable.value.refresh()
  }

  watch([searchQuery, currentPage], () => {
    refetchData()
  })

  const fetchTaskBlocks = (ctx, callback) => {
    store
      .dispatch('app-task-block-component/fetchTaskBlocks', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      },
      { root: true })
      .then(response => {
        const { taskBlocks, total } = response.data
        currentDataLength.value = taskBlocks.length
        callback(taskBlocks)
        totalTaskBlocks.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteTaskBlock = () => {
    store
      .dispatch('app-task-block-component/deleteTaskBlock', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveTaskBlockstatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchTaskBlocks,
    tableColumns,
    perPage,
    currentPage,
    totalTaskBlocks,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refTaskBlockComponentTable,

    searchQuery,

    resolveTaskBlockstatusVariant,
    refetchData,
    currentDataLength,

    toDeletedID,
    deleteTaskBlock,
  }
}
